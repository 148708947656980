import React from "react";

import {Col} from "react-bootstrap";

import Layout from "../../components/Layout";
import Background from "../../components/Background";
import Hero from "../../components/Hero";
import Statistics from "../../components/Statistics";
import Questions from "../../components/Questions";
import WeAreCTA from "../../components/WeAreCTA";
import Button from "../../components/Button";
import MotifColumn from "../../components/MotifColumn"

import styles from "./index.module.scss";


const AlbertaPage = () => {
    return (
        <Layout>
            <Background themeType={"oanda"}>
                <Hero backgroundType={"dark"} type={"alberta"}>
                    <MotifColumn>
                        <h1 >Helping Locals Grow Global</h1>
                        <p>Shift Connect Alberta is proud to serve our local community. From simple global money
                            transfers to in-depth foreign exchange strategies, we’ve got you covered. If you’d like to
                            learn more about how foreign exchange impacts your business, download our free white paper
                            on managing forex risk to learn about your vulnerabilities, risk management strategies, and
                            more. If you’re ready to partner with Shift Connect for expert support, get in touch today.
                            We’re here for you.
                        </p>
                    </MotifColumn>
                    <Col xs={12} lg={6} className={styles.gatedContentWrapper}>
                        <div className={styles.sendMoney}>
                            <h2>Your white paper has been sent to the email provided. Check your inbox!</h2>
                            <p>If you're ready to connect with an FX specialist, book a free 15-minute assessment
                                now.</p>
                            <div className="row justify-content-center">
                                <Button
                                    href='https://outlook.office365.com/owa/calendar/IntroCall@shiftconnect.com/bookings/'
                                    color={"purple"}
                                >
                                    Book FX Assessment
                                </Button>
                            </div>
                        </div>
                    </Col>
                </Hero>
                <WeAreCTA/>
                <Statistics themeType={"dark"}/>
                <Questions/>
            </Background>
        </Layout>
    );
};

export default (AlbertaPage);
